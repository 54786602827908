@value colors: "../../themes/Colors.css";
@value buttons: "../../themes/Buttons.css";
@value primary, secondary, third, mainBg from colors;

.infoIcon {
  color: primary;
  margin-right: 5px;
}

.jumbotron {
  background-image: url("/images/jumbotron-768.png");
  background-repeat: no-repeat;
  background-position: center bottom;
  background-color: secondary;
  width: 100%;
  padding-top: 60px;
  padding-left: 20px;
  padding-right: 20px;
  /* height: 677px; */
  /* padding-bottom: 220px; */
  height: 80vh;
  /* padding-bottom: 216px; */

  display: flex;
  flex-direction: column;
  align-items: center;
}

/* .jumbotronBottom {
  background-image: url("/images/jumbotron-mobile.webp");
  background-repeat: no-repeat;
  background-position: center bottom;
  background-color: secondary;
  background-size: contain;
  height: 200px;
  width: 100%;
} */

/* @media only screen and (max-width: 768px) { */

/* .jumbotron {
  background-color: secondary;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 60px;
  height: 480px;
  padding-bottom: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: -1px;
} */
/* } */

.jumbotronSignedIn {
  composes: jumbotron;
}

/* .title {
  font-size: 180%;
  color: white;
  text-align: center;
} */

.underTitle {
  font-size: 100%;
  color: white;
  text-align: center;
  margin-top: 15px;
  display: none;
}
.underTitleEnd {
  font-size: 100%;
  color: white;
  text-align: center;
  margin-top: 15px;
}

.createAccount {
  width: 100%;
  margin-top: 30px;
  display: flex;
  align-items: center;
  display: flex;
  flex-direction: column-reverse;
  min-height: 64px;
}

.play {
  composes: createAccount;
}

.playLink {
  width: 100%;
}

.playButton {
  composes: bg-primary from colors;
  composes: button from buttons;
  width: 100%;
  height: 50px;
  margin-top: 20px;
}

.playButtonEnd {
  composes: bg-primary from colors;
  composes: button from buttons;
  width: 100%;
  height: 50px;
  margin-top: 20px;
}
.ie11Button {
  composes: bg-primary from colors;
  composes: button from buttons;
}

.ie11Button:hover {
  opacity: 0.7;
}

.guestButton {
  composes: button from buttons;
  background-color: #6386a5;
  width: 100%;
  height: 50px;
  margin: 0;
  margin-top: 20px;
}

.guestButtonEnd {
  composes: button from buttons;
  background-color: #6386a5;
  width: 100%;
  height: 50px;
  margin: 0;
}

.guestLogin {
  width: 100%;
}

.guestLogin input::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #eee;
}
.guestLogin input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #eee;
  opacity: 1;
}
.guestLogin input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #eee;
  opacity: 1;
}
.guestLogin input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #eee;
}

.guestInput {
  margin-top: 20px;
  margin-right: 0px;
  background-color: third;
  height: 50px;
  font-size: 16px;
  border: 0;
  padding: 5px 10px 5px 10px;
  border-radius: 5px;
  color: white;
  width: 100%;
}

.guestInputEnd {
  margin-top: 0px;
  margin-right: 0px;
  background-color: third;
  height: 50px;
  font-size: 16px;
  border: 0;
  padding: 5px 10px 5px 10px;
  border-radius: 5px;
  color: white;
  width: 100%;
}

.guestInputError {
  composes: guestInput;
  border: 3px solid primary;
}

.guestInputErrorEnd {
  composes: guestInputEnd;
  border: 3px solid primary;
}
.playersOnlineCont {
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
  min-height: 40px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.playersOnlineNum {
  font-size: 16px;
  margin-left: 5px;
  margin-top: -2px;
}
.playersOnlineText {
  font-size: 75%;
  white-space: nowrap;
}

.playersOnlineNumCont {
  display: flex;
}

.infoCont {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
}

.infoSection {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding-top: 50px;
  padding-bottom: 50px;
}

.infoItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 40px 16px;
  text-align: center;
}

.infoImgCont {
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.infoTitle {
  padding-top: 10px;
  padding-bottom: 5px;
  font-size: 1.5rem;
  line-height: 2rem;
}

.text {
  color: #636363;
  width: 100%;
}

.phoneCont {
  display: flex;
  width: 100%;
  align-items: center;
  background-color: mainBg;
  border-bottom: 150px solid white;
}

.phoneSection {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  padding-top: 20px;
  padding-bottom: 50px;
}

.phoneTextCont {
  padding: 50px 0px;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.phoneImgCont {
  margin: 50px 0px -136px 0px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.phoneImgCont img {
  width: 300px;
  height: auto;
  object-fit: contain;
}

.line {
  width: 100%;
  height: 1px;
  background-color: white;
  margin: 20px auto 2px auto;
  color: white;

  text-align: center;
  border-bottom: 1px solid lines;
  line-height: 0.1em;
}

.spinner {
  height: 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* .leaguesBanner {
  position: absolute;
  top: 0;
  right: 0;
  transition: all 0.3s;
  display: none;
}

a.leaguesBanner:hover {
  filter: brightness(120%);
}

.leaguesBannerMobile {
  background-color: #f25c20;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  transition: all 0.3s;
  margin-top: 2px;
}

a.leaguesBannerMobile:active {
  filter: brightness(120%);
} */

.guestButton:active {
  opacity: 0.6;
}
.guestButtonEnd:active {
  opacity: 0.6;
}

.playButton:hover {
  opacity: 0.7;
}
.playButtonEnd:hover {
  opacity: 0.7;
}

.guestButton:hover {
  opacity: 0.7;
}
.guestButtonEnd:hover {
  opacity: 0.7;
}

@media only screen and (min-width: 769px) {
  /* .leaguesBanner {
    display: none;
  }

  .leaguesBannerMobile {
    display: none;
  } */

  .jumbotron {
    background-image: url("/images/jumbotron-1400.png");
    background-color: secondary;
    padding-top: 75px;
    padding-bottom: 90px;
    background-repeat: no-repeat;
    background-position: center bottom;
    height: 550px;
    padding-left: 20px;
    padding-right: 20px;
    /* max-height: auto; */
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .phoneImgCont img {
    width: 385px;
    height: 550px;
  }

  /* .title {
    font-size: 300%;
  } */
  .underTitle {
    font-size: 130%;
    display: block;
  }

  .createAccount {
    width: auto;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin-top: 35px;
  }

  .play {
    composes: createAccount;
    margin-top: 35px;
  }

  .playLink {
    width: auto;
  }
  .playButton {
    padding: 11.5px 20px;
    height: 44px;
    min-width: 150px;
    width: auto;
    margin-right: 10px;
    margin-left: 10px;
  }

  .playButtonEnd {
    padding: 11.5px 20px;
    height: 44px;
    min-width: 225px;
    width: auto;
    margin-right: 10px;
    margin-left: 10px;
  }
  .guestButton {
    padding: 11.5px 20px;
    height: 44px;
    min-width: 150px;
    width: auto;
    margin-left: 10px;
    margin-right: 0px;
  }

  .guestButtonEnd {
    padding: 11.5px 20px;
    height: 44px;
    min-width: 150px;
    width: auto;
    margin-left: 10px;
    margin-right: 0px;
  }

  .guestLogin {
    width: auto;
  }

  .guestInput {
    width: 260px;
    height: 44px;
  }
  .guestInputEnd {
    width: 260px;
    height: 44px;
  }
  .playersOnlineCont {
    align-items: flex-start;
  }
  .infoSection {
    flex-direction: row;
    max-width: 1062px;
  }
  .infoItem {
    width: 33%;
  }
  .phoneSection {
    flex-direction: row;
    justify-content: space-around;
    max-width: 1012px;
  }
  .appButtons {
    flex-direction: row;
  }
  .phoneTextCont {
    text-align: left;
    align-items: flex-start;
    padding-right: 2rem;
    max-width: 60%;
  }
  .phoneText {
    width: 100%;
  }
  .phoneImgCont {
    margin: 50px 30px 0px 30px;
    width: 30%;
  }

  .guestInput {
    margin-right: 10px;
  }
  .guestInputEnd {
    margin-right: 10px;
  }
}

/* .ieNotice {
  display: block;
  text-align: center;
  color: #fff;
  font-size: 24px;
  line-height: 32px;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
  padding-top: 40px;
} */

/* Hide IE banner by default */
/* .ieNoticeInfo {
  display: none;
} */

/* Show IE banner on IE10/11 */
/* @media screen and (-ms-high-contrast: active),
  screen and (-ms-high-contrast: none) {
  .ieNoticeInfo {
    display: block;
    text-align: center;
    background-color: #f6f6f6;
    padding: 20px;
  }
} */
