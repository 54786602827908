@value colors: '../../themes/Colors.css';
@value third, mainBg from colors;

.cont {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.spinner {
  margin: 0 auto;
  width: 70px;
  text-align: center;
}

.spinner > div {
  width: 18px;
  height: 18px;
  background-color: #fff;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.blue > div {
  background-color: third;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.spinnerPage {
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  /* height: 500px; */
  min-height: 720px;
  /* padding-top: 300px;
  padding-bottom: 300px; */
  align-items: center;
  justify-content: center;
  background-color: mainBg;
}

@media only screen and (min-width: 768px) {
  .spinnerPage {
    min-height: 620px;
  }
}
