.button {
  border: none;
  color: white;
  padding: 11.5px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
  font-weight: 600;
  border-radius: 5px;
}
